@import "../../assets/theme/Variables.scss";

.sidebar-main {
  padding: 40px 20px;
  width: 250px;
  color: $dark-grey;
  display: flex;
  justify-content: space-between;
  flex-flow: column;
  // max-height: 600px;
  // height: 600px;
  height: 100%;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 2;
  h5 {
    font-weight: $semiBold;
    margin: 0;
    font-size: 16px;
  }
  .dropdown {
    .btn {
      background-color: transparent;
      border: none;
      color: $inner-primary;
      padding: 0;
      text-transform: uppercase;
      font-size: 12px;
      &:active {
        background-color: transparent;
        color: $inner-primary;
      }
    }
    svg {
      width: 10px;
      height: 10px;
      margin-left: 5px;
      fill: #00bfff;
    }
  }
  ul {
    @extend %list-spacing;
    //max-height: 400px;
    //overflow: auto;
    margin: 10px 0;
    width: 100%;
    li {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font-size: 12px;
      gap: 12px;
      margin-bottom: 10px;
      cursor: pointer;
      //background-color: #f5f5f5;
      &:last-child {
        margin: 0;
      }
      p {
        margin: 0;
        display: flex;
        align-items: center;
        gap: 5px;
        .color-status {
          width: 10px;
          display: block;
          height: 10px;
          border-radius: 100px;
          background-color: $danger;
        }
      }
    }
  }

  .filter-data{
    max-height: 400px;
    overflow-y: auto;
    padding-right: 5px;
    &::-webkit-scrollbar {
      width: 4px;
    }
    li{
      cursor: default;
    }
  }

  // .real-time{
  //   min-height: 365px;
  //   overflow: auto;
  // }
  .dropdown-menu{
    padding: 10px 0px 0px;
    overflow: hidden;
    max-width: 166px;
    box-shadow: 0 6px 12px rgba(0,0,0,0.175);
    border: 1px solid #eee;
    ul{
      margin: 10px 0px 0px;
    }
    li{
      margin: 0px 15px 10px;
      display: block;
      width: auto;
      color: #08c;
      background-color: #f5f5f5;
      padding: 7px 10px;
      font-size: 13px;
      line-height: normal;
      &:hover{
        color: #fff;
        background-color: #08c;
      }
      &:nth-last-child(1){
        margin: 0px 15px 0px;
      }
      &.dropdown-item{
        &:nth-last-child(1){
          margin-bottom: 15px;
        }
      }
    }
  }
  //missing-filters
  .missing-filters{
    padding: 22px;
    background-color: #005b7a;
    text-align: center;
    p{
      color: white;
      font-size: 13px;
      margin: 0px 0px 10px;
    }
    .missing-filters-inline{
      display: flex;
      gap: 8px;
      justify-content: center;
      align-items: center;
      img{
        height: 18px;
      }
      .upgrade-action{
        color: white;
        text-decoration: underline;
        font-size: 11px;
        white-space: nowrap;
      }
    }
  }

}
.historical{
  // height: 300px;
  position: relative;
  display: flex;
  flex-direction: column;
  //align-items: flex-start;
  //justify-content: flex-end;
  .historical-dropwown{
    //top: -180px !important;
    right: 0 !important;
    position: absolute;
    // transform: translate(0px, -53.9167px) !important;
    bottom: 58px;
    left: 0;
    display: flex;
    // &.show-dropdown {
    //   display: flex;
    // }
  }
  .dropdown-toggle{
    &:after{
      transform: rotate(180deg);
    }
  }
}

.button-align-sidebar {
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 0px 15px 20px;
  justify-content: flex-end;
  button {
    padding: 7px 10px;
    //width: 100%;
    font-size: 12px;
    width: 110px;
  }
}

.historical-dropwown {
  ul {
    li {
      &.active {
        background-color: #08c;
        color: #fff;
      }
    }
  }
  &.show-custom-range-width {
    display: flex;
      align-items: center;
      min-width: 864px !important;
  }
  &.custom-range-hidden {
    display: none;
  }
}