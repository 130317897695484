$baseColor: #303030;
$primary: #0b506e;
$secondary: #353535;
$tertiary: #212529;

$darkGrey: #282828;
$grey: #6c757d;
$dark: #000000;
$success: #00c53b;
$danger: #dc3545;
$warning: #ffd500;
$white: #ffffff;
$sky: #2f6eba;
$blue: #0000af;

//font waight
$light: 300;
$regular: 400;
$semiBold: 500;
$bold: 600;
$extraBold: 700;
