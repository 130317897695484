@import "../../../assets/theme/Variables.scss";

.admin-sidebar{
    position: fixed;
    left: 0;
    top: 0;
    width: 250px;
    .sidebar-top{
        text-align: center;
        margin-bottom: 20px;
        padding: 20px 0px;
        img{
            width: 120px;
        }
    }
    .sidebar-mid{
        height: calc(100vh - 165px);
        overflow-y: auto;
        a{
            color: $white;
            font-size: 16px;
            line-height: 20px;
            display: flex;
            align-items: center;
            padding: 15px 20px;
            text-decoration: none;
            border-radius: 0px 10px 10px 0px;
            margin-right: 15px;
            margin-bottom: 5px;
            svg{
             width: 18px;
             height: 18px;
             min-width: 18px;   
             margin-right: 10px;
            }
            &:hover, &.active{
                background-color: $primary;
            }
        }
    }

    .sidebar-bottom{
        text-align: center;
        border-top: 1px solid $white;
        padding: 20px 0px 30px;
        margin: 0px 15px;
        h6{
            font-size: 14px;
            line-height: 18px;
            color: $white;
            margin: 0px;
            font-weight: $regular;
        }
        p{
            font-size: 12px;
            line-height: 16px;
            color: $white;
            margin: 0px;
            font-weight: $regular;
            span{
                color: #F40069;
            }
        }
    }
}