@import "../../../assets/theme/Variables.scss";

.setup-main {
  color: $dark-grey;
  margin: 100px 0;
  h1 {
    font-size: 24px;
    font-weight: 500;
  }
  p {
    font-size: 14px;
  }
  textarea {
    resize: none;
    &:focus {
      outline: none;
      box-shadow: none;
      border: 1px solid $input-grey;
    }
  }

  .installing-text {
    text-align: left;
    font-size: 14px;
    width: 40%;
    margin-top: 40px;
    p {
      padding-bottom: 10px;
      border-bottom: 1px solid #ddd;
      &:last-child {
        border: none;
      }
      a {
        color: #4a4a4a;
        text-decoration: none;
      }
    }
  }
}
 //textarea-group

 .textarea-group{

  position: relative;

  .copy-icon{

    position: absolute;

    right: 9px;

    top: 0;

    z-index: 1;

    cursor: pointer;

    background-color: #efefef;

    width: 28px;

    height: 28px;

    display: flex;

    align-items: center;

    justify-content: center;

    border: 1px solid #555555;

    border-radius: 0px 0px 0px 4px;

    img{

      width: 16px;

      height: 16px;

      object-fit: contain;

    }

  }
}
    //textarea-group karan-system
    // .textarea-group{
    //   position: relative;
    //   .copy-icon{
    //     position: absolute;
    //     right: 9px;
    //     top: 0;
    //     z-index: 1;
    //     cursor: pointer;
    //     background-color: #efefef;
    //     width: 28px;
    //     height: 28px;
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;
    //     border: 1px solid #555555;
    //     border-radius: 0px 0px 0px 4px;
    //     img{
    //       width: 16px;
    //       height: 16px;
    //       object-fit: contain;
    //     }
    //   }

    // }
