@import "../../../assets/theme/Variables.scss";

.userauth-main {
  text-align: center;
  background: url("../../../assets/images/userauth_bg.jpg") no-repeat center;
  background-size: cover;
  padding: 20px 0;
  height: 100vh;

  .userauth-top {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0px 15px;
    button {
      background-color: transparent;
      padding: 0;
      border: none;
      color: $link-color;
      font-size: 12px;
      text-transform: uppercase;
      &:hover{
        color: #23527c;
      }
    }
    p,
    a {
      font-size: 12px;
      margin: 0;
      text-decoration: none;
      color: #135572; 
    }
    a {
      color: $link-color;
      &:hover{
        color: #23527c;
      }
    }
  }

  .logo {
    width: 160px;
    object-fit: contain;
    margin: 0 auto;
  }
  .signup-form-header {
    text-align: center;
    margin-top: 20px;
    h2 {
      color: $primary;
      font-weight: $extraBold;
      font-size: 40px;
    }
  }
  .userauth-form {
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    overflow: hidden;
    width: 450px;
    margin: 0 auto;
    margin-top: 20px;
    h4 {
      padding: 22px 15px;
      font-weight: $extraBold;
      color: $white;
      background-color: $primary;
      margin: 0;
    }
    form {
      padding: 45px;
      background-color: $bg-grey;

      .forgot-check {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        span {
          color: #337ab7;
          cursor: pointer;
          font-size: 12px;
          font-weight: $regular;
          text-transform: uppercase;
          &:focus, &:hover{
            color: #23527c;
          }
        }
        label {
          color: #555555 !important;
        }
        .forgot-link {
          color: $dark-grey;
          text-decoration: underline;
          padding: 0;
          background-color: transparent;
          border: none;
        }
        .form-check-input {
          width: 15px;
          height: 15px;
          padding: 0;
          margin-top: 2px;
          cursor: pointer;
        }
      }
    }
    &.signup-form {
      text-align: left;
      box-shadow: none;
      padding: 0px 10px;
      form {
        background-color: transparent;
        label {
          font-size: 14px;
          font-weight: $extraBold;
          color: $primary;
        }
        .form-check-label{
          font-weight: 400;
        }
      }
    }
  }
  .auth-bottom {
    margin-top: 25px;
    color: $primary;
    font-size: 14px;
    a {
      font-weight: $extraBold;
      color: $primary;
      text-decoration: none;
    }
  }
}
