//color variable -----------
$primary: #0b506e;

$inner-primary: #00bfff;
$link-color: #337ab7;
$bg-grey: #e6e6e6;
$primary-btn: #f4364c;
$white: #fff;
$main-grey: #555555;
$input-grey: #9a9a9a;
$light-grey: #dfdfdf;
$bg-grey: #f0f0f0;
$dark-grey: #4a4a4a;
$dark: #000;
$danger: #ff0000;
$success: #55b26a;
$dark-success: #39914c;
$themeDanger: #a94442;

//font weight --------
$light: 300;
$regular: 400;
$semiBold: 500;
$bold: 600;
$extraBold: 700;

//list style ---------
%list-spacing {
  list-style: none;
  padding: 0;
  margin: 0;
}





//Just for test have to remove this in future
$baseColor: #f6ffe1;
$secondary: #161616;
$tertiary: #6D818E;
$darkGrey: #282828;
$grey: #707070;
$warning: #ffd500;
$sky: #2f6eba;
$blue: #0000af;