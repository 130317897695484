@import "../../../assets/theme/Variables.scss";

.settings-main {
  position: relative;
  .link-btn {
    background-color: transparent;
    border: none;
    color: $inner-primary;
    text-transform: uppercase;
    font-size: 14px;
    padding: 0;
    text-transform: capitalize;
    text-decoration: none;
    font-size: 12px;
    &:hover {
      color: #1f6b8b;
    }
    span {
      color: $dark-grey;
    }
  }
  h3 {
    color: $main-grey;
    font-size: 26px;
    margin: 20px 0;
  }
  .settings-tab {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
   
    button {
      color: $inner-primary;
      background-color: transparent;
      padding: 10px 15px;
      border-radius: 4px 4px 0 0;
      border: 0;
      border-bottom: 1px solid transparent;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: 400;
      &.active {
        border-bottom: 1px solid #555;
        color: $main-grey;
        font-weight: 500;
        &:hover {
          background-color: $white;
          border-bottom: 1px solid #555;
        }
      }
      &:hover{
        background-color: #eee;
      }
    }
  }
  .settings-btns-wrapper {
    display: flex;
    justify-content: space-between;
  }

  .subscription-details {
    width: 100%;
    h4 {
      color: $main-grey;
      font-size: 21px;
    }
    table {
      font-size: 14px;
      color: $main-grey;
      width: 100%;
      tr {
        border-bottom: 1px solid #dcdcdc;
        td {
          padding: 10px;
          vertical-align: middle;
          button {
            background-color: transparent;
            color: $inner-primary;
            border: none;
            padding: 0;
            &:hover {
              color: #1f6b8b;
            }
          }
          &.detail-small-text {
            color: #a29f9f;
          }
        }
      }
    }

    .subscription-radio-group {
      h6 {
        color: $main-grey;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 20px;
      }
      ul {
        @extend %list-spacing;
        line-height: 27px;
        li {
          display: flex;
          align-items: center;
          input {
            margin-right: 10px;
          }
          label {
            font-size: 14px;
            color: $main-grey;
            cursor: pointer;
          }
        }
      }

      .slidecontainer {
        width: 100%;
        margin-bottom: 15px;
        input {
          width: 100%;
        }
      }
    }

    //textarea-group
    .textarea-group {
      position: relative;
      .copy-icon {
        position: absolute;
        right: 18px;
        top: 0;
        z-index: 1;
        cursor: pointer;
        background-color: #efefef;
        width: 28px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #555555;
        border-radius: 0px 0px 0px 4px;
        img {
          width: 16px;
          height: 16px;
          object-fit: contain;
        }
      }
      .form-control{
        font-size: 14px;
      }
    }
  }

  .subscription-table {
    margin-top: 30px;
    h4 {
      color: $main-grey;
      font-size: 21px;
    }
    .table-responsive {
      .table {
        font-size: 14px;
        border: 1px solid #dcdcdc;
        thead {
          background-color: #808080;
          tr {
            td {
              padding: 12px;
              background-color: #808080;
            color: $white;
            }
          }
        }
        tr {
          td {
            vertical-align: middle;
            .pass-show-btn {
              display: flex;
              align-items: center;
              p {
                margin: 8px 0 0;
              }
            }
          }
        }
      }
    }
    .delete-row {
      padding: 10px;
      border-top: 1px solid #dcdcdc;
      border-bottom: 1px solid #dcdcdc;
    }
    .subscription-heading-align {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .header-para {
        margin: 0;
        font-size: 12px;
        color: #888888;
      }
    }
  }
  .settings-tab-section{
    padding: 0px 30px;
  }
}

.transparent-btn {
  background-color: transparent;
  color: $inner-primary;
  border: none;
  padding: 0;
  font-size: 11px;
  text-transform: uppercase;
  white-space: nowrap;
  position: relative;
  display: flex;
  align-items: center;
  gap: 3px;
  &:hover {
    color: #1f6b8b;
  }
  .up-errow {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid black;
    display: inline-block;
    border-radius: 4px;

    &.rotate {
      transform: rotate(180deg);
      transition-duration: 0.3ms;
    }
  }
}

.subscription-bottom-reset-align {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 15px;
  border-top: 1px solid #ddd;
}

//right sidebar ui start -----------
// .right-sideprofile-btn {
//   position: fixed;
//   top: 50px;
//   right: 0;
//   background-color: $inner-primary;
//   padding: 6px 8px;
//   border-radius: 5px 0 0 5px;
//   border: none;
//   img {
//     width: 14px;
//     object-fit: contain;
//   }
//   &.left-align {
//     right: 450px;
//     width: 30px;
//     img {
//       transform: rotate(180deg);
//     }
//   }
// }

.sideprofile-main {
  position: relative;

  .right-sideprofile-btn {
    position: fixed;
    top: 50px;
    right: 0;
    background-color: $inner-primary;
    padding: 6px 8px;
    border-radius: 5px 0 0 5px;
    border: none;
    transition-duration: 0.5s;
    img {
      width: 14px;
      object-fit: contain;
    }
    &.left-align {
      right: 450px;
      width: 30px;
      img {
        transform: rotate(180deg);
      }
    }
  }

  .sideprofile-main-inner {
    background-color: $white;
    box-shadow: 0 4px 10px 0 darkgrey;
    position: fixed;
    top: 0;
    right: -450px;
    width: 450px;
    //height: 670px;
    transition-duration: 0.5s;
    height: calc(100vh - 21px);
  }
  &.show {
    .right-sideprofile-btn {
      .left-align {
        right: 0;
        img {
          transform: rotate(0deg);
        }
      }
    }
    .sideprofile-main-inner {
      right: 0px;
      transition-duration: 0.5s;
    }
  }
  .sideprofile-tab {
    display: flex;
    align-items: center;
    z-index: 1;
    gap: 7px;
    border-bottom: 4px solid #fff;
    button {
      padding: 22px 0;
      font-size: 13px;
      border: none;
      width: 100%;
      color: gray;
      opacity: 0.5;
      background: $white;
      font-weight: 500;
      &.active {
        color: $main-grey;
        opacity: 1;
        background: rgba(85, 85, 85, 0.1);
      }
      &:hover {
        background: rgba(85, 85, 85, 0.1);
      }
    }
  }
  .fetch-data-list {
    // overflow: auto;
    // height: calc(100vh - 65px);
    .date-partition {
      background-color: $inner-primary;
      margin: 0;
      padding: 8px 0;
      color: $white;
      text-align: center;
      font-size: 14px;
    }
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        padding: 20px;
        font-size: 12px;
        &:hover {
          background-color: rgba(30, 119, 180, 0.09) !important;
        }
        &:nth-child(odd) {
          background-color: #eeeeee;
        }
        .flag-style {
          width: 20px;
          object-fit: contain;
          margin: 0 10px;
        }
        .fetch-data-desc {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 10px;
          &:last-child {
            margin: 0;
          }
          .desc-flex-content {
            display: flex;
            align-items: center;
            gap: 10px;
          }

          p {
            margin: 0;
            span {
              color: #aaa;
            }
          }
          ul {
            list-style: none;
            margin: 0;
            padding: 0;
            display: flex;
            align-items: center;
            gap: 15px;
            li {
              padding: 0;
              background-color: transparent;
              img {
                width: 15px;
                height: 15px;
                object-fit: contain;
              }
            }
          }
        }
        .session-details {
          list-style: none;
          margin: 0;
          padding: 20px 0 0 0;
          li {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 11px;
            border-bottom: 1px solid #ddd;
            padding: 8px 10px;
            background-color: transparent;
            &:last-child {
              border: none;
            }
            p {
              margin: 0;
              span{
                color: #aaa;
                font-size: 9px;
              }
            }
            &:nth-child(1) {
              p {
                color: #578398;
              }
            }
            &:hover {
              background-color: transparent !important;
            }
          }
        }
      }
    }
  }

  .pages-table {
    padding: 20px;
    &.pages-table-scroll {
      overflow-y: auto;
      height: calc(100vh - 88px);
    }
    .got-it-section {
      padding: 14px;
      background-color: #f0f0f0;
      font-size: 13px;
      margin-bottom: 15px;
      p {
        color: gray;
      }
      button {
        background-color: transparent;
        padding: 0;
        margin: 0;
        color: gray;
        border: none;
        text-transform: uppercase;
        font-size: 11px;
        &:hover {
          color: #272626;
        }
      }
    }
    .refresh-main {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      p {
        margin: 0;
      }
      .transparent-btn {
        background-color: transparent;
        padding: 0;
        border: none;
      }
    }
    table {
      font-size: 12px;
      width: 100%;
      margin-top: 10px;
      color: #555;
      td {
        padding: 5px;
        button {
          img {
            width: 10px;
            height: 10px;
            transition: 0.4s all;
            margin: 0 5px;
            fill: #555;
            &.rotate {
              rotate: 180deg;
            }
          }
        }
        .remove {
          color: #ff0000;
        }
      }
      thead {
        border-bottom: 1px solid #ddd;
        button {
          color: #555;
        }
        .clear-all {
          color: $inner-primary;
        }
      }
    }
  }
}
.account-close {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 15px;
  width: 40%;
  svg {
    margin-bottom: 10px;
  }
  p {
    margin: 0;
  }
}
.payment-card {
  min-height: 355px;
  margin-bottom: 15px;
}

.locked-notification {
  background-color: #d86060;
  padding: 20px;
  // margin-bottom: 20px;
  // margin-left: -20px;
  // margin-right: -20px;
  h5 {
    color: white;
    font-size: 12px;
    margin-bottom: 10px;
  }
  p {
    color: white;
    font-size: 10px;
    margin-bottom: 10px;
  }
}

.session-listed-content {
  background-color: $white;
  position: absolute;
  bottom: 0;
  z-index: 1;
  width: 100%;
  left: 0;
  padding: 10px;
  p {
    font-size: 12px;
    color: #000;
    margin: 0px;
    text-align: right;
  }
}

.sideprofile-main-inner{
  .infinite-scroll-component {
    height: calc(100vh - 86px) !important;
  }
  &.with-upgradebox-sidebar{
    .infinite-scroll-component {
      height: calc(100vh - 173px) !important;
    }
  }
}