@import "../../assets/theme/Variables.scss";

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px;
  background-color: #f0f0f0;
  a {
    .logo {
      width: 88px;
      object-fit: contain;
      height: 29px;
    }
  }
  h5 {
    margin: 0;
    color: $dark-grey;
    .tooltip-content{
      left: 50%;
      margin-left: -100px;
    }
  }
  ul {
    @extend %list-spacing;
    display: flex;
    align-items: center;
    li {
      position: relative;
      display: flex;
      align-items: center;
      &:hover {
        background: #eee;
        background: #e6e6e6;
      }
      a {
        display: flex;
        align-items: center;
        text-decoration: none;
        font-size: 12px;
        gap: 5px;
        color: #555555;
        cursor: pointer;
        padding: 11px 20px 9px 20px;
        img {
          width: 14px;
          height: 14px;
          object-fit: contain;
        }
      }
    }
  }
}

.custom-dropdown {
  background-color: #1e2331;
  border-radius: 4px;
  position: absolute;
  top: 33px;
  right: 0;
  width: 190px;
  z-index: 1;
  .arrow-up {
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #1e2331;
    top: -8px;
    right: 40px;
    position: absolute;
  }
  ul {
    margin: 0;
    padding: 10px 0;
    list-style: none;
    flex-direction: column;
    gap: 0;
    align-items: flex-start;
    border-bottom: 1px solid rgba($white, 0.1);
    li {
      cursor: pointer;
      width: 100%;
      img {
        margin-right: 5px;
        opacity: 0.4;
      }
      a {
        padding: 8px 15px;
        color: $white;
        // &:hover {
        //   background-color: rgba(255, 255, 255, 0.07);
        //   img {
        //     opacity: 1;
        //   }
        // }
      }
      &:hover{
        background-color: rgba(255,255,255,0.07);
        a{
          img{
            opacity: 1;
          }
        }
      }
    }
  }
}

