@import "../../../assets/theme/Variables.scss";

.admin-header{
  background-color: $darkGrey;
  padding: 10px 0px;
  position: sticky;
  top: 0;
  z-index: 1;
 .section-inner{
  .header-breadcrumb{
      background-color: $secondary;
      border-radius: 10px;
      padding: 12px 15px;
      ul{
          margin: 0px;
          padding: 0px;
          display: flex;
          align-items: center;
          li{
              list-style: none;
              margin-right: 10px;
              a{
                  display: inline-block;
                  color: $white;
                  text-decoration: none;
                  font-size: 14px;
                  line-height: 18px;
                  font-weight: $bold;
                  svg{
                      width: 22px;
                      height: 22px;
                      min-height: 22px;
                      fill: rgba($white, 0.5);
                  }
                  &:hover{
                      svg{
                          fill: $white;
                      }
                      
                  }
              }
              span{
                  svg{
                      width: 14px;
                      height: 14px;
                      min-height: 14px;
                      fill: rgba($white, 0.5);
                  }
              }
              &.active{
                  a{
                      pointer-events: none;
                  }
              }
          }
      }
  }
  
  .header-right{
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  //en-select
  .en-select{
    margin-right: 10px;
    select{
      background-color: $secondary;
      color: $white;
      border-color: $secondary;
      outline: none;
      box-shadow: none;
      border-radius: 6px;
    }
  }

   //user-drop
   .user-drop {
      display: flex;
      justify-content: flex-end;
      .user-drop-btn {
        display: flex;
        align-items: center;
        border: 0px;
        background-color: transparent;
        color: $white;
        min-width: 135px;
        border-radius: 10px 10px 0px 0px;
        &.show{
          backdrop-filter: blur(11px);
          background-color: rgba($darkGrey, 0.29);
        }
        .circle-img {
          margin-right: 10px;
          img {
            width: 40px;
            height: 40px;
            min-width: 40px;
            border-radius: 100px;
          }
        }
        svg {
          margin-left: 10px;
          width: 14px;
          min-width: 14px;
        }
        &:after {
          display: none;
        }
      }

      .dropdown-menu {
        //box-shadow: 0px 0px 10px #00000014;
        border-radius: 0px 0px 10px 10px;
        padding: 10px 10px 10px 10px;
        border: 0px;
        margin-top: 14px;
        backdrop-filter: blur(11px);
        background-color: rgba($darkGrey, 0.29);
        min-width: 135px;
        margin-top: -2px !important;
        width: 100%;
        li {
          border-top: 1px solid rgba($white, 0.08);
          .dropdown-item {
            color: $dark;
            font-size: 14px;
            border-bottom: 1px solid rgba($dark, 0.1);
            padding: 5px 0px;
            font-weight: $semiBold;
            color: $white;
            display: flex;
            align-items: center;
            justify-content: space-between;
            svg {
              width: 14px;
              height: 14px;
              min-width: 14px;
              fill: $white;
            }
            &:hover {
              background-color: transparent;
              color: $primary;
              svg{
                fill: $primary;  
              }
            }
          }
          &:nth-last-child(1) {
            .dropdown-item {
              border-bottom: 0px;
            }
          }
        }
      }
    }
 }
}