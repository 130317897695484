@import "./Variables.scss";

* {
  font-family: "Fira Sans", sans-serif;
}

//spacing style -----------
.mt-35 {
  margin-top: 35px !important;
}

.px-40 {
  padding-left: 40px;
  padding-right: 40px;
}

.pr100 {
  padding-right: 100px;
}

.pt30 {
  padding-top: 30px;
}

.font10 {
  font-size: 10px;
}

.font12 {
  font-size: 12px !important;
}

.font14 {
  font-size: 14px;
}

.font20 {
  font-size: 20px;
}

.font22 {
  font-size: 22px;
}

.fontRegular {
  font-weight: $regular;
}

.fontBold {
  font-weight: $bold;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-primary {
  color: $primary !important;
}

.text-input-grey {
  color: $input-grey;
}

.disable-prop {
  opacity: 0.7;
}

a{
  color: #337ab7;
  &:focus, &:hover{
    color: #23527c;
  }
}

//common container style -----------
// .main-container {
//   // padding: 50px 0;

// }
.admin-container {
  display: flex;
  flex-direction: row;
}

.main-box {
  display: flex;

  .dashboard-container {
    width: calc(100% - 250px);
    background-color: $white;
    padding: 20px 20px 20px 0;
    // z-index: -1;
    .container-bg {
      background-color: $bg-grey;
      padding: 20px 50px 50px;
      position: relative;
    }
  }
}

select:required:invalid {
  color: #c7c7cb;
}

option[value=""][disabled] {
  display: none;
}

option {
  color: black;
}

// // select {
// //   color: #9e9e9e;
// // }
// option:not(:first-of-type) {
//   color: black;
// }
// /* the modification */
// option:first-of-type {
//   display: none;
// }

//common input style ------------
.form-group {
  margin-bottom: 20px;

  .control-sm {
    padding: 10px 15px;
  }

  input,
  select {
    background-color: $white;
    padding: 19px 15px;
    color: $dark;
    border: 1px solid $light-grey;

    &:focus {
      outline: none;
      box-shadow: 0 0 0 3px #80a7ef;
      //border: 0px solid #80a7ef;
      border-color: #dfdfdf;
    }

    &::placeholder {
      color: #c7c7cb;
      font-weight: $regular;
    }
  }

  .input-icon {
    display: flex;
    align-items: flex-start;
    position: relative;
    flex-flow: column;

    input {
      padding-right: 45px;
    }

    button {
      background-color: transparent;
      position: absolute;
      top: 15px;
      right: 10px;
      border: none;
      padding: 5px;

      img {
        width: 20px;
        height: 20px;
      }
    }
  }
}

.auth-msg {
  display: flex;
  align-items: center;
  font-size: 14px;
  margin: 5px 0;

  img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }

  &.danger {
    color: $danger;
  }
}

//common button style -------------
.theme-btn {
border-radius: 4px;
  font-size: 14px;
  padding: 10px 40px;
  font-weight: $bold;

  &.primary-btn {
    background-color: $primary-btn;
    color: $white;
    font-weight: $bold;
    border: 1px solid $primary-btn;
  }

  &.theme-danger-btn {
    background-color: $themeDanger;
    color: $white;
    font-weight: $bold;
    border: 1px solid $themeDanger;
    &:hover{
      background-color: #a92b28;
      border-color: #a92b28;
    }
  }

  &.success-btn {
    background-color: $success ;
    border: 1px solid $success; 
    color: $white ;
    transition: 0.4s all;

    &:hover {
      background-color: $dark-success;
      border: 1px solid $dark-success;
    }
  }

  &.success-outline-btn {
    background-color: $white;
    border: 1px solid $success;
    color: $success;
    transition: 0.4s all;

    &:hover {
      background-color: $dark-success;
      border: 1px solid $dark-success;
      color: $white;
    }
    &.transparent {
      background-color: transparent;
      &:hover {
        background-color: transparent;
        color: $success;
      }
    }
  }

  &.white-btn {
    background-color: $white;
    border: 1px solid $input-grey ;
    border-radius: 5px !important;
    color: $dark ;
    transition: 0.4s all;
    font-weight: $regular;

    &:hover {
      background-color: #eee;
    }
  }

  &.btn-lg {
    padding: 15px 105px;
  }

  &.btn-md {
    padding: 15px 20px;
  }

  &.btn-sm {
    padding: 7px 30px !important;
    font-size: 14px !important;
  }

  &.icon-btn{
    display: flex;
    align-items: center;
    gap: 4px;
    img{
      width: 14px;
      min-width: 14px;
      height: 14px;
    }
  }
}
.theme-btn[disabled] {
  opacity: 0.5;
  pointer-events: none;
}
div.historical-cluster-tooltip {
  position: absolute;
  padding: 15px;
  font-family: FiraSans;
  font-size: 13px;
  font-weight: 400;
  color: white;
  border: 0;
  border-radius: 8px;
  pointer-events: none;
}
.spinner-border {
  width: 15px;
  height: 15px;
  margin-left: 4px;
}

//common modal style -------------
.common-modal {
  .modal-dialog {
    .modal-content {
      box-shadow: 0 2px 18px 6px rgba(0, 0, 0, 0.3);
      border-radius: 6px;
      border: none;
      overflow: hidden;

      .modal-header {
        background-color: $primary;
        border-radius: 0;
        text-align: center;

        h4 {
          color: $white;
          font-size: 20px;
          width: 100%;
          padding: 0;
        }
      }

      .common-body {
        h4 {
          font-size: 22px;
          font-weight: $bold;
        }

        .airborne-inline {
          display: flex;
          align-items: center;
          border-bottom: 1px solid #dedede;
          padding: 15px 0 10px;

          img {
            margin-top: -88px;
            padding-left: 68px;
          }
        }

        .airbourne-desc {
          text-align: left;
          font-size: 14px;
          padding: 20px 0;
          border-bottom: 1px solid #dedede;

          h6 {
            font-weight: $bold;
            margin-bottom: 0;
          }

          .airbourne-link {
            p {
              a {
                color: $inner-primary;
                text-decoration: none;
              }
            }
          }
        }

        .airbourne-bottom {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 20px 0;
          font-size: 14px;
        }
      }
    }
  }

  &.show-modal {
    display: block;

    &::after {
      position: absolute;
      content: " ";
      width: 100%;
      height: 100%;
      background-color: rgba($dark, 0.5);
      top: 0;
      left: 0;
      z-index: -1;
    }
  }
}

//common card style -----------
.common-card {
  border-radius: 2px;
  box-shadow: 2px 2px 10px #e9e9e9;
  width: 155px;
  height: 105px;
  background-color: $white;
}

.full-card {
  width: 100% !important;

  .common-card {
    padding: 10px;
    width: 100%;
    display: flex;
    align-items: flex-end;

    p {
      margin: 0;
    }
  }
}

// .selectAPage{
//   background-image:url(../assets/images/select_apage.png);
//   background-size:cover
// }
// .selectAPage {
//   display: none;
//   background-repeat: no-repeat;
//   background-position: center;
//   position: fixed;
// }

.selectAPage {
  background-image: url(../images/select_a_page.png);
  background-size: cover;
  // left: 100px !important;
  // right: 100px !important;
  // top: 100px !important;
  position: fixed;
  display: none;
  &:hover {
    display: block !important;
  }
}
// .selectAPage:hover {
//   display: block !important;
// }

//upgrade subscription style -----------

.upgrade-subscription {
  .subscription-heading {
    color: $primary;
    font-weight: $bold;
  }

  .card {
    cursor: pointer;
    padding: 20px 15px 30px 15px;
    display: flex;
    align-items: center;
    flex-direction: column;
    border: none;
    background-color: #f7f7f7;
    border-radius: 4px;

    &:hover {
      background-color: #e4e4e4;
    }

    &.active {
      color: $white;
      background-color: #1d343f;

      h5,
      p {
        color: $white;
      }
    }

    h5 {
      color: $primary;
      font-size: 16px;
      font-weight: $bold;
      text-transform: uppercase;
    }

    .price-details {
      text-align: center;
      color: $inner-primary;

      .price {
        font-size: 56px;
      }

      p {
        font-size: 14px;
        margin: -12px 0 0 0;
      }
    }

    h6 {
      font-size: 18px;
      font-weight: $regular;
      text-align: center;
      margin-top: 10px;
      min-height: 45px;
    }

    ul {
      @extend %list-spacing;
      margin-bottom: 15px;

      li {
        font-size: 11px;
        text-align: center;

        span {
          font-weight: $semiBold;
        }
      }
    }

    button {
      font-weight: $bold;
    }
  }

  .card-form {
    background-color: #f7f7f7;
    padding: 15px 20px 13px 20px;
    border-radius: 4px;
    min-height: 356px;
    form {
      .form-inner-headiing{
        label{
          font-weight: 500;
        }
      }
      .form-group {
        label {
          color: $primary;
          font-size: 13px;
          font-weight: $semiBold;
        }

        .form-control {
          background-color: $white;
          height: 40px;
          border: solid 1px $success;
          border-radius: 3px;
          padding: 13px;
          font-size: 14px;
          font-family: sans-serif;

          select:required:invalid {
            color: gray;
          }

          option[value=""][disabled] {
            display: none;
          }

          option {
            color: black;
          }
        }
      }
    }

    .encrypt-text {
      font-style: italic;
      font-weight: $light;
      color: $primary;
      font-size: 13px;
      margin: 0;
      align-items: center;
      display: flex;
      gap: 5px;
      img {
        width: 13px;
        height: 13px;
        object-fit: contain;
      }
    }

    .StripeElement {
      background-color: $white;
      height: 40px;
      border: solid 1px $success;
      border-radius: 3px;
      padding: 13px;
    }
  }

  .process-upgrade {
    background-color: #f7f7f7;
    padding: 15px 20px 13px 20px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      width: 350px;
      font-style: italic;
      font-size: 12px;
      color: $primary;
      margin: 0;
    }
  }
}

.country-select {
  padding: 8px 33px 8px 13px !important;
}

.upgrade-main-footer {
  margin: 2px 20px;
  padding: 12px 0px;

  .upgrade-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    p,
    h5 {
      color: $primary;
      margin: 0;
      font-size: 16px;
    }

    h5 {
      font-weight: $bold;
    }

    img {
      width: 104px;
      object-fit: contain;
    }
  }
}

//tracking code modal style ---------
.tracking-code-modal {
  h4,
  p {
    color: $main-grey;
  }

  .congrats-header {
    border-bottom: 1px solid $light-grey;
    padding: 20px;

    h4 {
      font-weight: $bold;
      font-size: 18px;
    }

    p {
      margin: 0;
      font-size: 14px;
    }
  }

  .congrats-body {
    padding: 30px 50px;
    font-size: 14px;
  }
}

.form-select {
  cursor: pointer;
}

.cursor-class {
  cursor: pointer;
}

//admin panel css start
//theme-table
.theme-table {
  width: 100%;

  .table {
    --bs-table-bg-state: transparent !important;
    --bs-table-bg: transparent !important;
    tr {
      td {
        vertical-align: middle;
        border-bottom: 0px;
      }
    }

    thead {
      background-color: $tertiary;

      tr {
        td {
          color: $white;
          padding: 15px 15px;
          font-size: 12px;
          font-weight: $regular;
          white-space: nowrap;

          span {
            font-weight: 300;
          }

          @media screen and (max-width: 1366px) and (min-width: 768px) {
            font-size: 12px;
          }

          @media only screen and (max-width: 767px) {
            font-size: 12px;
          }
        }
      }
    }

    tbody {
      border: 0px;

      tr {
        background-color: rgba($white, 0.1);

        td {
          border: 0px;
          padding: 15px 15px;
          color: $white;
          font-size: 12px;
          font-weight: $regular;

          a {
            color: $white;
          }

          @media screen and (max-width: 1366px) and (min-width: 768px) {
            font-size: 12px;
          }

          @media only screen and (max-width: 767px) {
            font-size: 12px;
          }

          .inline-action {
            display: flex;
            align-items: center;
          }

          .status-circle {
            width: 14px;
            height: 14px;
            border-radius: 100%;

            &.danger-bg {
              background-color: $danger;
            }

            &.success-bg {
              background-color: $success;
            }
          }

          .status-icon {
            svg {
              width: 14px;
              height: 14px;
              min-height: 14px;
            }

            &.warning {
              svg {
                fill: $warning;
              }
            }
          }

          .circle-img {
            img {
              width: 40px;
              height: 40px;
              min-width: 40px;
            }
          }

          .form-control {
            font-size: 12px;
          }
        }

        &:nth-child(odd) {
          background-color: rgba($secondary, 0.3);
        }
      }
    }

    tfoot {
      border: 0px;

      tr {
        td {
          padding: 15px 30px;
          color: $dark;
          font-size: 14px;
          font-weight: $regular;
          white-space: nowrap;
          border: 0px;

          @media screen and (max-width: 1366px) and (min-width: 768px) {
            font-size: 14px;
          }

          @media only screen and (max-width: 767px) {
            font-size: 12px;
          }
        }
      }
    }
  }
}

//main
.admin-main {
  width: 100%;
  display: flex;
  background-color: $secondary;

  .main-wrapper {
    width: calc(100% - 250px);
    margin-left: 250px;
    background-size: cover;
    background-position: left top;

    .main-wrapper-inner {
      display: flex;
      padding-top: 15px;
      padding-bottom: 15px;

      .wrapper-pages {
        //width: calc(100% - 250px);
        width: calc(100% - 0px);
        min-height: 100vh;
      }
    }
  }
}

//admin-theme-card
.admin-theme-card {
  background-color: rgba($darkGrey, 0.5);
  backdrop-filter: blur(11px);
  border-radius: 6px;
  min-height: calc(100vh - 100px);

  .card-top {
    padding: 20px;

    .card-heading {
      h3 {
        color: $white;
        font-size: 18px;
        margin: 0px;
      }
    }

    .common-head-action {
      display: flex;
      align-items: flex-start;
      justify-content: end;
      gap: 15px;
      button {
        white-space: nowrap;
      }
    }
  }

  .card-mid {
    padding: 0px 20px 20px;
  }
}

//common-search
.common-search {
  min-width: 300px;

  .right-icon-input {
    position: relative;

    .form-control {
      padding-right: 40px;
      padding: 10px 15px;
      outline: none;
      box-shadow: none;
      border-radius: 0px;
    }

    span {
      position: absolute;
      top: 0px;
      right: 0px;
      z-index: 2;
      background-color: $primary;
      width: 43px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        height: 16px;
        fill: $white;

        @media screen and (max-width: 1366px) and (min-width: 768px) {
          height: 14px;
        }

        @media only screen and (max-width: 767px) {
          height: 12px;
        }
      }
    }
  }
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: rgba($dark, 0.1);
}

::-webkit-scrollbar-thumb {
  background: #c3c3c3;
}

.inline-btn {
  display: flex;
  align-items: center;
  gap: 10px;
}

.order-complete-modal {
  text-align: center;
  padding: 20px 0;
  .successful-icon {
    width: 100px;
    height: 100px;
    object-fit: contain;
    margin-bottom: 10px;
  }
  h3 {
    font-weight: $extraBold;
    font-size: 22px;
    color: #0b506e !important;
  }
  .logo {
    width: 180px;
    object-fit: contain;
    margin-top: 40px;
  }
}

.hover-tooltip {
  position: relative;
  .tooltip-content {
    position: absolute;
    bottom: 34px;
    right: 0;
    width: 100%;
    max-width: 200px;
    background-color: rgba($dark, 1);
    border: 6px;
    padding: 8px;
    text-align: center;
    border-radius: 4px;
    font-size: 12px;
    line-height: 16px;
    color: #fff;
    display: none;
    line-break: normal;
    min-width: 100px;
    &:after {
      content: " ";
      position: absolute;
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid rgba($dark, 1);
      bottom: -8px;
      left: 50%;
      margin-left: -8px;
    }
    &.tooltip-content-bottom {
      bottom: auto;
      z-index: 1;
      top: 39px;
      &:after {
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 8px solid rgba($dark, 1);
        border-top: 0px;
        bottom: auto;
        top: -8px;
      }
    }
    &.header-tooltip {
      color: #555;
      background-color: #fff;
      padding: 8px;
      font-size: 14px;
      box-shadow: #c7c7cb 0px 0px 10px;
      max-width: auto;
      font-weight: 400;
      max-width: 300px;
      word-break: break-all;
      &:after {
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 8px solid #fff;
      }
    }
    &.right-tooltip{
      right: 0;
      &:after {
        left: auto;
        right: 5px;
        margin-left: 0px;
      }
    }
    &.left-tooltip{
     right: auto;
      &:after {
        left: 5px;
        margin-left: 0px;
      }
    }
    &.center-tooltip{
      right: auto;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &:hover {
    .tooltip-content {
      display: inline-block;
    }
  }
}
.main-box {
  .common-custom-model {
    position: absolute;
    // width: 100%;
    // height: 100%;
    background: rgba($dark, 0.8);
    z-index: 1;
    top: -18px;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .inner-wrapper {
      width: 60%;
      margin: auto;
      text-align: center;
      svg{
        margin-bottom: 15px;
      }
      p {
        color: $white;
        font-size: 14px;
        font-weight: $light;
        line-height: 18px;
      }
      .sub-tittle {
        color: $white;
        font-size: 18px;
      }
      h2 {
        font-size: 16px;
        color: $white;
        margin: 10px 0;
      }
    }
  }
}
.limitation-wrapper {
  background: $primary-btn;
  padding: 5px 20px;
  margin-bottom: 5px;
  p {
    font-size: 14px;
    color: $white;
    margin-bottom: 5px;
  }
  h4 {
    font-size: 16px;
    color: $white;
    margin-top: 10px;
    font-weight: $bold;
  }
  .sub-tittle {
    color: $white;
    font-size: 14px;
    font-weight: $bold;
  }
  ul {
    padding-left: 15px;
    li {
      color: $white;
      p {
        margin-bottom: 0px;
      }
    }
  }
}
.downgrade-btns {
  display: flex;
  justify-content: center;
  gap: 15px;
}

.trial-banner-img {
  position: relative;
  font-size: 14px;
  .banner-img-set {
    position: absolute;
    top: 0;
    right: 0;
  }
  .banner-desc {
    width: 52%;
    color: $primary;
    padding-top: 140px;
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      li {
        font-weight: 600;
      }
    }
    .tooltip-hover {
      font-weight: 600;
      color: $primary;
      display: flex;
      align-items: center;
      text-transform: uppercase;
      gap: 5px;
      .tooltip-onimage {
        position: relative;
        &:hover {
          .tooltip-list {
            display: block;
          }
        }
        img {
          cursor: pointer;
        }
        .tooltip-list {
          display: none;
          position: absolute;
          background-color: #1d343f;
          color: #fff;
          min-width: 600px;
          bottom: 28px;
          left: -34px;
          border-radius: 5px;
          padding: 20px;
          text-align: center;
          ul {
            list-style: none;
            margin: 0;
            padding: 0;
            display: flex;
            li {
              border-right: 1px solid rgba($white, 0.3);
              padding: 0 10px;
              min-width: 200px;
              &:last-child {
                border: none;
              }
            }
          }
          .title {
            font-weight: 600;
            font-size: 16px;
            margin-bottom: 5px;
          }
          h3 {
            font-size: 35px;
            font-weight: 400;
            margin: 0;
          }
          .amount {
            p {
              font-size: 14px;
              text-transform: capitalize;
            }
          }
          h5 {
            text-transform: capitalize;
            margin-bottom: 18px;
            font-size: 16px;
            font-weight: 500;
          }
          .points-list {
            p {
              font-size: 14px;
              font-size: 12px;
              font-weight: 400;
              margin: 0;
            }
          }
          .arrow-down {
            position: absolute;
            bottom: -8px;
            left: 34px;
            width: 0;
            height: 0;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-top: 8px solid #1d343f;
          }
        }
      }
    }
  }
}

.trial-modal {
  .modal-content {
    background: linear-gradient(10deg, #aee3fb 0%, #f9fdff 40%);
    background-clip: padding-box;
    height: 490px;
  }
}

.trial-perios-desc {
  text-align: center;
  color: $primary;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  h2 {
    font-size: 1.6rem;
  }
  a {
    color: $primary;
  }
  p {
    font-size: 14px;
  }
  .trial-align-btn {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
  }
}

.button-align-modal {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}

.logout-btn {
  background-color: transparent;
  border: none;
  padding: 0;
  text-decoration: underline;
  color: $primary;
  font-size: 14px;
  font-weight: 300;
  margin-top: 20px;
  &:hover {
    color: #1d343f;
  }
}

.flex-align-modal-btn {
  flex-direction: column;
  & > div {
    display: flex;
    gap: 10px;
  }
}

.company_logo {
  width: 100px;
  margin: 20px auto;
}

.button-label {
  position: relative;
  p {
    position: absolute;
    top: 45px;
    left: 0;
    right: 0;
  }
}

#scrollableDiv {
  position: relative;
}

.sidebar-bottom-bar {
  padding: 20px;
  color: $white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #005b7a;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  a {
    color: $white;
    font-size: 12px;
  }
  p {
    margin: 0;
    font-size: 13px;
    font-weight: 400;
  }
  .logo-lock {
    display: flex;
    flex-direction: column;
  }
  img {
    width: 71px;
    margin-top: 3px;
  }
}

.error-box-delete {
  background-color: #f2dede;
  border: 1px solid #ebccd1;
  border-radius: 4px;
  padding: 15px;
  margin-bottom: 20px;
  h3 {
    color: #a94442 !important;
    font-size: 20px !important;
    margin: 0 !important;
  }
}

.error-para-show {
  color: #999;
  font-size: 13px;
}
.copy-icon-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  width: 100%;
}

.copy-icon {
  position: relative;
  &:hover {
    .textarea-tooltip {
      display: block;
    }
  }
  .textarea-tooltip {
    position: absolute;
    background-color: $white;
    border-radius: 5px;
    padding: 5px 10px;
    top: 0;
    right: 44px;
    width: 135px;
    font-size: 14px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 1px 2px 22px #ddd;
    display: none;
    .arrow-right {
      width: 0;
      height: 0;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-left: 10px solid $white;
      position: absolute;
      top: 5px;
      right: -10px;
    }
  }
}

.right-menu-session-height {
  h4 {
    color: #555555;
    font-weight: 400;
    font-size: 20px;
    text-align: center;
    margin-top: 10px;
  }
}

.right-menu-session-height-scrollable {
  //height: calc(100vh - 133px);
  h4 {
    color: #555555;
    font-weight: 400;
    font-size: 20px;
    text-align: center;
    margin-top: 10px;
  }
}

//react-tooltip
.react-tooltip{
  border-radius: 7px !important;
  z-index: 999;
  background: #000 !important;
}
.selected-url-tooltip {
  position: absolute;
  padding: 10px;
  font: 12px sans-serif;
  color: black;
  border: 0;
  border-radius: 8px;
  pointer-events: none;
  background-color: rgb(255, 255, 255);
}

div.modal-dialog:has(> div div.delete-account-modal){
  max-width: 600px;
  .modal-body{
    padding: 10px 40px;
    .error-box-delete{
      margin-bottom: 10px;
    }
    .modal-warning-brief{
      border-bottom: 1px solid rgba($dark, 0.1);
      margin-bottom: 10px;
      p{
        color: #a94442;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 5px;
      }
    }

    .check-content{
      .forgot-check{
        display: flex;
        align-items: center;
        gap: 10px;
        .form-control{
          height: 24px;
          width: 24px;
        }
        label{
          color: #a94442;
          font-size: 14px;
          font-weight: 500;
        }
      }
    }

    .error-para-show{
      color: #0b506e;
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 10px;
    }
    .form-group{
      margin-bottom: 10px;
      label{
        font-weight: 500;
      }
    }

  }
}

.fw-500{
  font-weight: 500;
}  

.analytics-card{
  position: relative;
}

