@import "../../assets/theme/Variables.scss";

.hide-footer-btn {
  position: fixed;
  bottom: 0px;
  right: 20px;
  background-color: $inner-primary;
  padding: 2px 12px;
  border-radius: 5px 5px 0 0;
  border: none;
  transition-duration: 0.5s;
  &.up-footer-btn {
    bottom: 64px;
    img{
      transform: rotate(360deg);
    }
  }
  img {
    width: 20px;
    height: 20px;
    transform: rotate(180deg);
  }
}
.footer-main {
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: $primary;
  color: $white;
  padding: 10px;
  width: 100%;
  transition-duration: 0.5s;
  z-index: 1;
  &.footer-hide-main {
    bottom: -66px;
  }
  .footer-desc {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 120px;
    .overall-desc {
      text-transform: uppercase;
      font-weight: $bold;
      opacity: 1;
      p,
      h5 {
        margin: 0;
      }
      p {
        font-size: 12px;
      }
      h5 {
        font-size: 16px;
      }
    }
    .dropdown {
      .dropdown-menu {
        left: -36px !important;
        bottom: 6px !important; 
        li {
          font-size:14px;
          cursor: pointer;
        }
      }
    }
  }

  .today-status{
    h4 {
      font-size: 22px;
      margin: 0;
      opacity: 0.51;
    }
  }

  .footer-list-align {
    @extend %list-spacing;
    display: flex;
    align-items: center;
    gap: 60px;
    li {
      text-align: center;
      position: relative;
      min-height: 45px;
      &:after {
        content:"";
        position: absolute;
        right: -30px;
        top: 0;
        height: 100%;
        width: 1px;
        background-color: rgba(#e7e7e7, 0.15);
      }
      // &:nth-child(3),
      // &:nth-child(7) {
      //   border-right: 2px solid rgba(#e7e7e7, 0.15);
      //   border-left: 2px solid rgba(#e7e7e7, 0.15);
      //   padding: 0 10px;
      // }

      &:nth-last-child(1){
        &::after{
          display: none;
        }
      }
      .arrow-inline {
        display: flex;
        align-items: center;
        justify-content: center;
        .arrow-count {
          display: flex;
          flex-direction: column;
        }
        &.first-line-overall {
          flex-direction: column;
          gap: 2px;
          margin-right: 100px;
          align-items: flex-start;
    opacity: 0.5;
          h6 {
            margin: 0;
            font-size: 12px;
          }
          h4 {
            font-size: 16px;
            white-space: nowrap;
          }
        }
      }
      h4 {
        font-size: 22px;
        margin: 0;
      }
      p {
        margin: 0;
        font-size: 12px;
        font-weight: $bold;
        opacity: 0.51;
      }
    }
  }
}
